<template>
  <div style="height: calc(100% - 10px)">
    <div class="header">
      <div class="nowTime">今天:{{ getNowDate }}({{ nowWeek }})</div>
      <div class="operTime">
        <a-icon type="notification" />:今日专科医生就诊{{
          NewsCount.count1
        }}人，预防疫苗{{ NewsCount.count3 }}人，健康体检{{
          NewsCount.count2
        }}人。
      </div>
    </div>
    <div class="main">
      <div class="left fn-main">
        <div
          class="fn-block calendar-container"
          style="min-height: 350px; max-height: 350px"
        >
          <div class="doctor-box" style="">
            <!-- <a-calendar :fullscreen="false" @panelChange="calendarChange" /> -->
            <my-calendar @dateChange="dateChange"></my-calendar>
          </div>
        </div>
        <div class="fn-block">
          <div class="doctor-box">
            <div class="title">
              <span class="active"
                ><a-icon type="heart" style="margin-right: 5px" />服务类别</span
              >
            </div>
            <div style="overflow: hidden">
              <a-radio-group v-model="typeValue" @change="onTypeChange">
                <a-radio class="doctor-list" :value="0"> 专科医生 </a-radio>
                <a-radio class="doctor-list" :value="1"> 疫苗接种 </a-radio>
                <a-radio class="doctor-list" :value="2"> 健康体检 </a-radio>
              </a-radio-group>
            </div>
            <div class="title" style="display: flex">
              <div style="flex: 1;justify-content: flex-start;align-items: center;display: flex">
                <span class="active"><a-icon type="solution" style="margin-right: 5px" />{{
                    AllList[typeValue].title
                  }}
                </span>
              </div>
              <div @click="selectAllCancel" style="flex: 1;display: flex;align-items: center;justify-content: flex-end;color: #1890ff;cursor: pointer">
                <a-icon style="font-size: 16px" :type=" checkState == true? 'check-square' : 'border'"/><span style="margin-left: 5px;">{{ checkState == true ? '取消' : '全选' }}</span>
              </div>
            </div>
            <div>
              <div
                v-for="(item, index) in AllList[typeValue].list"
                :key="index"
                :class="item.active ? 'doctor-list active' : 'doctor-list'"
                @click="doctorClick(item, item.id)"
              >
                <a-icon
                  v-if="!item.active"
                  style="font-size: 16px"
                  type="border"
                />
                <a-icon
                  v-else
                  style="font-size: 16px"
                  type="check-square"
                  :style="{ color: item.color }"
                />
                <span
                  :style="{
                    color: item.active ? item.color : '',
                    marginLeft: '10px',
                  }"
                  >{{ item.title_scc }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="w100 flex center-block">

          <div class="fn-block" style="overflow: auto; padding-top: 0px">
            <a-empty v-if="doctorArrangements.length == 0" style="margin-top: 100px"/>
            <div v-if="typeValue == 0"   class="box-container">
              <div
                class="sub-container"
                v-for="(item, index) in doctorArrangements"
                :key="index"
              >
                <div class="sub-date">
                  <a-icon type="calendar" />{{ item.time }}
                </div>
                <div class="sub-list" v-if="item.doctorList.length > 0">
                  <div
                    class="sub-item"
                    v-for="(items, indexs) in item.doctorList"
                    :key="indexs"
                  >
                    <!-- <div class="sub-name">
                      <span class="user-name-btn btn1">{{
                        items.title_scc
                      }}</span>
                    </div>
                    <div class="sub-num">
                      <span
                        >待诊{{ items.numberToBeDiagnosed }}, 已诊{{
                          items.numberOfConfirmedCases
                        }}</span
                      >
                    </div> -->
                    <!-- <div class="sub-user-list">
                      <div
                        class="sub-user sub-active1"
                        v-for="(itemss, indexss) in items.orderList"
                        :key="indexss"
                      >
                        <span class="sub-time"
                          >{{ itemss.name
                          }}<span style="float: right">{{
                            itemss.time
                          }}</span></span
                        >
                      </div>
                    </div> -->
                    <div class="sub-user-list">
                      <div
                        class="sub-user sub-active1"
                        v-for="(itemss, indexss) in items.orderList"
                        :key="indexss"
                        :style="{
                          backgroundColor: colorRgb(capitalize(items.id)),
                          borderColor: capitalize(items.id),
                        }"
                      >
                        <span class="sub-time">{{ itemss.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="typeValue == 1" class="box-container">
              <div
                class="sub-container"
                v-for="(item, index) in doctorArrangements"
                :key="index"
              >
                <div class="sub-date">
                  <a-icon type="calendar" />{{ item.time }}
                </div>
                <div class="sub-list" v-if="item.vaccineList.length > 0">
                  <div
                    class="sub-item"
                    v-for="(items, indexs) in item.vaccineList"
                    :key="indexs"
                  >
                    <!-- <div class="sub-name">
                      <span class="user-name-btn btn1">{{
                        items.title_scc
                      }}</span>
                    </div>
                    <div class="sub-num">
                      <span
                        >待诊{{ items.numberToBeDiagnosed }}, 已诊{{
                          items.numberOfConfirmedCases
                        }}</span
                      >
                    </div> -->
                    <div class="sub-user-list">
                      <div
                        class="sub-user sub-active1"
                        v-for="(itemss, indexss) in items.orderList"
                        :key="indexss"
                        :style="{
                          backgroundColor: colorRgb(capitalize(items.id)),
                          borderColor: capitalize(items.id),
                        }"
                      >
                        <span class="sub-time">{{ itemss.name }}</span>
                        <span>{{itemss.time}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="typeValue == 2" class="box-container">
              <div
                class="sub-container"
                v-for="(item, index) in doctorArrangements"
                :key="index"
                ref="sancon"
              >
                <div class="sub-date">
                  <a-icon type="calendar" />{{ item.time }}
                </div>
                <div class="sub-list" v-if="item.physicalExaminationList.length > 0">
                  <div
                    class="sub-item"
                    v-for="(items, indexs) in item.physicalExaminationList"
                    :key="indexs"
                  >
                    <div class="sub-user-list">
                      <div
                        class="sub-user sub-active1"
                        v-for="(itemss, indexss) in items.orderList"
                        :key="indexss"
                        :style="{
                          backgroundColor: colorRgb(capitalize(items.id)),
                          borderColor: capitalize(items.id),
                        }"
                      >
                        <span class="sub-time">{{ itemss.name }}</span>
                        <span>{{itemss.time}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="fn-block w400">
          <div class="title">
            <span class="active"
              ><a-icon
                type="usergroup-delete"
                style="margin-right: 5px"
              />待就诊</span
            >
          </div>
          <div class="doctor-box">
            <div
              class="receive-list active"
              v-for="(item, index) in pendingService"
              :key="index"
            >
              <div class="user-info">
                <div style="width: 100%">
                  <div class="user-name" v-if="item && item.service_item_list">
                    {{ item.service_item_list[0].appointment_time }}
                  </div>
                  <div class="order-id">{{ item.patient_name }}</div>
                </div>
                <div class="receive-time">
                  <a-tag color="orange"> 待接诊 </a-tag>
                </div>
              </div>
            </div>
            <a-empty v-if="pendingService.length == 0"/>
          </div>
        </div>
        <div class="fn-block w400">
          <div class="title">
            <span class="active"
              ><a-icon
                type="usergroup-delete"
                style="margin-right: 5px"
              />待接单</span
            >
          </div>
          <div class="doctor-box">
            <div
              class="receive-list active"
              style="display: flex"
              v-for="(item, index) in PendingOrder"
              :key="index"
            >
              <div style="width: 100%">
                <div class="doctor-info d4">
                  <div class="doctor-name" style="font-size: 14px">
                    <div>
                      <span style="margin: 0 10px 0 0px"
                        >就诊人：{{ item.patient_name }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="user-info" style="font-size: 12px">
                  <div class="user-name">订单号:</div>
                  <div class="order-id">{{ item.order_id }}</div>
                </div>
              </div>
              <div class="receive-time" style="padding-top: 15px">
                <a-button type="primary" @click="goOrder(item.id)">
                  马上接单
                </a-button>
              </div>
            </div>
          </div>
          <a-empty v-if="PendingOrder.length == 0"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "dashboard",
  data() {
    return {
      colorList: [
        "#5B8FF9",
        "#61DDAA",
        "#65789B",
        "#F6BD16",
        "#7262fd",
        "#78D3F8",
        "#9661BC",
        "#F6903D",
        "#008685",
        "#F08BB4",
        "#CDDDFD",
        "#CDF3E4",
        "#CED4DE",
        "#CDF3E4",
        "#FCEBB9",
        "#D3CEFD",
        "#D3EEF9",
        "#DECFEA",
        "#FFE0C7",
        "#BBDEDE",
        "#FFE0ED",
      ],
      NewsCount: {},
      getNowDate: "",
      nowWeek: "",
      AllList: [
        {
          title: "专科医生",
          list: [],
        },
        {
          title: "疫苗接种",
          list: [],
        },
        {
          title: "健康体检",
          list: [],
        },
      ],
      page: {
        start_time: "",
        end_time: "",
        selectIds: "",
      },
      doctorList: [],
      doctorArrangements: [],
      pendingService: [],
      PendingOrder: [],
      typeValue: 0,

      selectState: false,
    };
  },
  filters: {},
  computed: {
    /**
     * @author wheat
     * */
    checkState() {
      let list = this.AllList[this.typeValue].list;
      return list.filter(item => item['active'] == true).length == 0 ? false : list.filter(item => item['active'] == true).length  == this.AllList[this.typeValue].list.length  ? true : false;
    },
  },
  mounted() {
    this.NewsTarget();
    this.getDate();
    this.getAllList();
    this.pendingServiceOrder();
    this.PendingOrderPage();
  },
  methods: {
    /**
     * 全选，取消全选
     * @author wheats
     * */
    selectAllCancel() {
      this.page.selectIds = "";
      this.doctorList = [];
      if (this.selectState == true) {
        this.unSelectThree(this.typeValue);
      } else {
        this.selectThree(this.typeValue)
      }
      this.selectState = !this.selectState;
    },

    /** 获取门诊下所有专科医生，疫苗和体检
     * @author
     * */
    async getAllList() {
      let res = await Api.getAllList();
      if (res.code == "0") {
        this.AllList[0].list = res.doctorList;
        this.AllList[1].list = res.vaccineList;
        this.AllList[2].list = res.physicalExaminationList;
        for (let i = 0; i < this.AllList.length; i++) {
          for (let j = 0; j < this.AllList[i].list.length; j++) {
            this.AllList[i].list[j].color = this.colorList[j];
          }
        }
        this.selectThree(0);
      } else {
        this.$message.error(res.message);
      }
    },
    /*
     * 选专科医生，疫苗接种，健康体检
     * @author wheats
     * */
    selectThree(index) {
      let temp = Object.assign([],this.AllList);
      for (let i = 0; i < temp[index].list.length; i++) {
        temp[index].list[i].active = true;
        this.doctorList.push(this.AllList[index].list[i].id);
      }
      this.AllList = temp;
      this.page.selectIds = this.doctorList.join(",");
      this.doctorArrangement();
    },
    /*
     * 选专科医生，疫苗接种，健康体检
     * @author wheats
     * */
    unSelectThree(index) {
      let temp = Object.assign([],this.AllList);
      for (let i = 0; i < temp[index].list.length; i++) {
        temp[index].list[i].active = false;
      }
      this.AllList = temp;
      this.page.selectIds = this.doctorList.join(",");
      this.doctorArrangement();
    },


    //获取门诊首页头部消息
    async NewsTarget() {
      let res = await Api.NewsTarget();
      if (res.code == "0") {
        this.NewsCount = res;
      } else {
        this.$message.error(res.message);
      }
    },
    // 获取当前日期
    getDate() {
      let getDate = new Date();
      let nowYear = getDate.getFullYear();
      let nowMonth = getDate.getMonth() + 1;
      let nowDay = getDate.getDate();
      let nowWeek = getDate.getDay();
      let a = new Array("日", "一", "二", "三", "四", "五", "六");
      let str = "周" + a[nowWeek];
      this.getNowDate = nowYear + "年" + nowMonth + "月" + nowDay + "日";
      this.nowWeek = str;
    },
    //获取门诊下所有专科医生，疫苗和体检
    // async getAllList() {
    //   let res = await Api.getAllList();
    //   if (res.code == "0") {
    //     this.AllList[0].list = res.doctorList;
    //     this.AllList[1].list = res.vaccineList;
    //     this.AllList[2].list = res.physicalExaminationList;
    //     for (let i = 0; i < this.AllList.length; i++) {
    //       for (let j = 0; j < this.AllList[i].list.length; j++) {
    //         this.AllList[i].list[j].color = this.colorList[j];
    //       }
    //     }
    //     this.selectThree(0);
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // },
    //分页获取待服务订单
    async pendingServiceOrder() {
      let res = await Api.pendingServiceOrder({ limit: "20", search: 'index', start_time: this.page.start_time, end_time: this.page.end_time });
      if (res.code == "0") {
        this.pendingService = res.page.list;
      } else {
        this.$message.error(res.message);
      }
    },
    //待确认订单，列表
    async PendingOrderPage() {
      let res = await Api.PendingOrderPage({ limit: "20" });
      if (res.code == "0") {
        console.log(res);
        this.PendingOrder = res.page.list;
      } else {
        this.$message.error(res.message);
      }
    },
    //获取医生安排信息
    async doctorArrangement() {
      let res;
      if (this.typeValue == 0) {
        // 专科医生
        // wheats修改
        if (this.page.selectIds != '') {
          res = await Api.doctorArrangement({ ...this.page });
        }

      } else if (this.typeValue == 1) {
        //疫苗接种

        // wheats修改
        if (this.page.selectIds != '') {
          res = await Api.vaccineArrangement({ ...this.page });
        }

      } else if (this.typeValue == 2) {
        // 健康体检
        if (this.page.selectIds != '') {
          res = await Api.healthCheckupArrangement({ ...this.page });
        }

      }
      if (res && res.returnList) {

        res.returnList.forEach(item=>{
          if (item && item.vaccineList) {
            let temp1 = item.vaccineList.filter(item1=>item1.orderList.length > 0)
            item.vaccineList = temp1;
          } else if (item && item.doctorList) {
            let temp2 = item.doctorList.filter(item2=>item2.orderList.length > 0)
            item.doctorList = temp2;
          } else if (item && item.physicalExaminationList) {
            let temp3 = item.physicalExaminationList.filter(item3=>item3.orderList.length > 0)
            item.physicalExaminationList = temp3;
          }
        })

        console.log(res.returnList)
        this.doctorArrangements = res.returnList;

      } else {
        this.doctorArrangements = [];
        // this.$message.error(res && res.message);
      }
    },
    //医生列表点击
    doctorClick(item, val) {

      let temp = Object.assign([], this.AllList);

      for(let i = 0; i < temp[this.typeValue].list.length; i++) {
        let item = temp[this.typeValue].list[i];
        if (val == item.id ) {
          item.active = !item.active;
          break
        }
      }


      // item.active = !item.active;
      //
      // if (this.doctorList.indexOf(val) === -1) {
      //   // 不存在,则添加
      //   this.doctorList.push(val);
      // } else {
      //   // 存在,则删除
      //   this.doctorList.splice(this.doctorList.indexOf(val), 1);
      // }
      this.AllList = temp;
      let temp1 = temp[this.typeValue].list.filter(item=>item.active == true);
      this.page.selectIds = temp1.map(item=>item.id).join(",");
      this.doctorArrangement();
    },

    calendarChange(value) {
      console.log(value);
    },
    onTypeChange(e) {
      this.typeValue = e.target.value;
      this.page.selectIds = "";
      this.doctorList = [];
      this.selectThree(e.target.value);
    },
    // 跳转到订单
    goOrder(id) {
      this.$router.push({
        name: "order",
        params: {
          id: id,
        },
      });
    },
    dateChange(date) {
      this.page.start_time = date.starttime;
      this.page.end_time = date.endtime;
      this.doctorArrangement();
      this.pendingServiceOrder()
    },
    colorRgb(se) {
      console.log(se);
      // 16进制颜色值的正则
      let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
      // 把颜色值变成小写
      let color = se.toLowerCase();
      if (reg.test(color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (color.length === 4) {
          var colorNew = "#";
          for (var i = 1; i < 4; i += 1) {
            colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
          }
          color = colorNew;
        }
        // 处理六位的颜色值，转为RGB
        var colorChange = [];
        for (var i = 1; i < 7; i += 2) {
          colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
        }
        return "RGB(" + colorChange.join(",") + ",0.3)";
      } else {
        return color;
      }
    },
    capitalize(value) {
      console.log(value);
      for (let i = 0; i < this.AllList[this.typeValue].list.length; i++) {
        if (this.AllList[this.typeValue].list[i].id == value) {
          return this.AllList[this.typeValue].list[i].color;
        }
      }
    },
  },
};
</script>

<style scoped>
.main {
  display: flex;
  height: calc(100% - 50px);
}
.main .left {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main .center {
  flex: 1;
}
.main .right {
  flex-direction: column;
  align-items: center;
  display: flex;
}
.fn-block {
  /* background-color: #f0f2f5; */
  border-radius: 8px;
  padding: 10px;
  text-align: left;
  width: 300px;
  margin: 10px 0px 0px 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* height: calc(100% - 10px); */
  flex: 1;
}
.fn-block6 {
  background-color: rgba(255, 255, 255, 0.6) !important;
}
.w400 {
  width: 350px !important;
  margin-right: 10px;
}

.fn-main .fn-block:last-child {
  margin-bottom: 0;
}
.fn-block .title {
  font-size: 14px;
  margin: 10px 0;
}
.fn-block .title span {
  cursor: pointer;
}
.fn-block .title span.active {
  color: #1890ff;
}
.fn-block .title span.op {
  float: right;
}
.more-menu {
  transform: rotate(90deg);
}
.doctor-box {
  max-height: 100%;
  overflow: auto;
  padding-bottom: 40px;
}
.doctor-box .doctor-list:last-child {
  border-bottom: none;
}
.doctor-list {
  text-align: left;
  border-bottom: 1px solid #eee;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
}
.doctor-list.active {
  background-color: #1890ff1a;
}
.doctor-list .department {
  flex: 1;
}
.fn1 {
  color: #6ac461;
}
.fn2 {
  color: #4c68ff;
}
.fn3 {
  color: #f65ff7;
}
.fn4 {
  color: #c7690e;
}
.fn5 {
  color: #fd8f58;
}
.doctor-list .department span {
  flex: 1;
  background-color: #ffffff;
  padding: 2px 6px;
  border-radius: 4px;
}
.doctor-list .name {
  flex: 1;
  font-size: 14px;
}
.doctor-list .select {
  flex: 1;
  color: #666666;
}
.doctor-list .job {
  flex: 1;
  color: #666666;
}
.doctor-list .state {
  width: 25px;
  display: flex;
  align-items: center;
}
.doctor-list .state span {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ccc;
}
.doctor-list .state span.active {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e48a1b;
}
.doctor-box .new-list:first-child {
  margin-top: 20px;
}
.new-list {
  padding: 2px 10px 4px 10px;
}
.new-list.active {
  background-color: #ffffff;
  color: #333333;
  border-radius: 4px;
  border-left: 3px solid #fd8f58;
  box-shadow: -2px 2px 6px #eee;
}
.new-list .title {
  color: #666666;
  font-size: 14px;
}
.new-list .time {
  color: #b5b5b5;
  font-size: 12px;
}
.week-container {
  display: flex;
}
.week-container .week {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  color: #666666;
}
.day-container {
  display: flex;
  flex-wrap: wrap;
}
.day-container .day {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  height: 30px;
  width: 40px;
}

.receive-list {
  padding: 10px;
  background-color: #ffffff;
  color: #333333;
  border-radius: 4px;
}
.receive-list.active {
  border-left: 5px solid #fd8f58;
  box-shadow: -2px 2px 6px #eee;
}
.user-info {
  line-height: 30px;
  color: #666666;
  font-size: 14px;
  display: flex;
}

.user-info .receive-time {
  color: #999999;
  font-size: 12px;
}

.doctor-info {
  display: flex;
  font-size: 12px;
  align-items: center;
  line-height: 30px;
}
.doctor-name {
  display: flex;
  flex: 1;
}
.receive-state {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.doctor-box .receive-list {
  margin-bottom: 20px;
}
.d1 {
  color: #c86a0e !important;
}
.d2 {
  color: #13b1f7 !important;
}
.d3 {
  color: #f65ff7 !important;
}

.d5 {
  color: #73c185 !important;
}
.d6 {
  color: #fd7a2f !important;
}
.text-de {
  position: absolute;
  font-size: 14px;
  left: 10px;
  color: #ffffff;
  font-weight: bold;
}
.flex {
  display: flex;
}
.plr20 {
  padding: 0 20px;
}
.pl20 {
  padding-left: 20px;
}
.product-name-box {
  display: flex;
  font-size: 14px;
  align-items: center;
  width: 300px;
}
.product-name-box span.active {
  color: #333;
  text-decoration: underline;
  padding-right: 20px;
}
.w100 {
  width: 100%;
}
.box-container {
  width: auto;
  white-space: nowrap;
  height: 100%;
  display: flex;
}
.sub-container {
  border-right: 1px solid #1890ff29;
  min-width: 160px;
  white-space: nowrap;
  display: inline-block;
  height: 100%;
  width: 160px;
}
.sub-container {
  padding: 0 10px;
}
.sub-container:nth-child(2n + 0) {
  border-left: none;
}
.sub-item {
  //float: left;
  margin-bottom: 5px;
}
.sub-user-list {
  display: flex;
  flex-direction: column;
}
.sub-user-list .sub-user:last-child {
  margin-bottom: 0;
}
.sub-user {
  background-color: #fff;
  padding: 4px 40px 4px 10px;
  margin-bottom: 4px;
  border-radius: 4px;
}
.sub-user {
  display: flex;
  flex-direction: column;
}
.sub-user span {
  flex: 1;
}

.sub-active1 {
  border-left-width: 5px;
  border-style: solid;
}
.sub-num {
  text-align: center;
  margin: 10px 0;
}
.sub-name {
  text-align: center;
  margin: 20px 0;
}
.sub-time {
  color: #666;
}
.sub-active2 {
  border-left: 2px solid #73c185;
}
.user-name-btn {
  color: #ffffff;
  font-size: 14px;
  padding: 5px 30px;
  border-radius: 4px;
}
.btn1 {
  background-color: #1890ff;
}
.btn2 {
  background-color: #52ce6e;
}
.sub-date {
  color: #666666;
  font-size: 14px;
  text-align: center;
  padding: 10px 0px;
  border-bottom: 1px solid #1890ff29;
  margin-bottom: 5px;
}
.sub-date i {
  margin-right: 5px;
}
.header {
  background-color: #1890ff30;
  border-radius: 4px;
  display: flex;
  margin: 0px 10px;
  height: 40px;
  line-height: 40px;
}
.nowTime {
  line-height: 40px;
  width: 300px;
  padding-left: 20px;
  font-size: 14px;
}
.operTime {
  line-height: 40px;
  padding-left: 20px;
  color: #1890ff;
  font-size: 14px;
}
.operTime i {
  margin-right: 5px;
}
.center-block {
  padding: 0px;
  height: 100%;
  margin: 0px;
}
</style>
