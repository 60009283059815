import request from '@/common/request';
/**
 * 获取门诊首页头部消息
 */
export function NewsTarget(data) {
  return request({
    url: '/clinic/index/newsTarget',
    method: 'GET',
    params: data
  });
}
/**
 * 获取门诊下所有专科医生，疫苗和体检
 */
export function getAllList(data) {
  return request({
    url: '/clinic/index/getAllList',
    method: 'GET',
    data
  });
}

/**
* 获取医生安排信息
*/
export function doctorArrangement(data) {
  return request({
    url: '/clinic/index/doctorArrangement',
    method: 'POST',
    data
  });
}
/**
* 获取健康体检安排信息
*/
export function healthCheckupArrangement(data) {
  return request({
    url: '/clinic/index/healthCheckupArrangement',
    method: 'POST',
    data
  });
}
/**
* 获取疫苗安排信息
*/
export function vaccineArrangement(data) {
  return request({
    url: '/clinic/index/vaccineArrangement',
    method: 'POST',
    data
  });
}

/***
 * 分页获取待服务订单
 */
 export function pendingServiceOrder(params) {
  return request({
    url: '/clinic/order/pendingServiceOrder',
    method: 'GET',
    params
  });
}

/**
 * 待确认订单，列表
 */
 export function PendingOrderPage(params) {
  return request({
    url: '/clinic/order/pendingOrderpage',
    method: 'GET',
    params
  });
}